<template>
  <section class="mt-2">
    <b-card-group>
      <b-card no-body bg-variant="info" text-variant="white">
        <div style="width: 100%" class="text-left mt-4 mb-4 container">
          <b-col>
            <b-row>
              <b-col md="6" lg="4" class="mb-2">
                <b-row class="mb-2 text-white" align-v="baseline">
                  <b-col cols="2" md="3">
                    <b-img :src="img" alt="" rounded width="100px" />
                  </b-col>
                </b-row>
                <b-row class="mb-1 text-white">
                  <b-col>
                    <div style="font-size: 1em;">
                      {{ appName }} Merupakan platform Belajar Online bagi siapa saja yang ingin mengembangkan keahlian
                      <i>(Skill)</i> dan berbagi ilmu <i>(Sharing Knowlegde)</i> baik untuk kalangan Akademisi maupun
                      Profesional sehingga bisa bermanfaat untuk masyarakat luas sampai kita mati menjadi Amal Jariyah.
                      Karena setiap orang yang akan mengakses dan memberikan Donasi untuk setiap topiknya akan
                      disalurkan pada Program Sosial <i>(Charity)</i> untuk kepentingan masyarakat luas seperti Beasiswa
                      Pendidikan, Pembangunan tempat Ibadah, Santunan Dhuafa, Yatim Piatu dan Program sosial lainnya.
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6" lg="3" class="mb-2">
                <div style="font-size: 1em;" class="mb-2 text-white">
                  <b>Kontak</b>
                </div>
                <b-row class="mb-1">
                  <b-col>
                    <feather-icon icon="PhoneIcon" />
                    087825412805
                  </b-col>
                </b-row>
                <div class="d-flex">
                  <span style="margin-right: 5px">
                    <feather-icon icon="MailIcon" /></span>
                  <div style="word-wrap: break-word">
                    <small>info@sobari.id</small>
                  </div>
                </div>
              </b-col>
              <b-col md="6" lg="2" class="mb-2">
                <div style="font-size: 1em" class="mb-2 text-white">
                  <b>Layanan & Informasi</b>
                </div>
                <b-row class="mb-1">
                  <b-col>
                    <b-link :to="{ name: 'tentang-kami' }" class="text-white">
                      Tentang Kami
                    </b-link>
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col>
                    <b-link :to="{ name: 'kebijakan-privasi' }" class="text-white">
                      Kebijakan Privasi
                    </b-link>
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col>
                    <b-link :to="{ name: 'syarat-ketentuan' }" class="text-white">
                      Syarat & Ketentuan
                    </b-link>
                  </b-col>
                </b-row>
              </b-col>
              <!-- <b-col md="6" xl="3" class="mb-2">
                <div style="font-size: 1em" class="mb-2 text-white">
                  <b>Alamat</b>
                </div>
                <ol>
                  <li style="margin-bottom: 7px">
                    Jl.Jati No.68, Bandung
                  </li>
                </ol>
              </b-col> -->
            </b-row>
          </b-col>
        </div>
      </b-card>
      <!-- </b-col> -->
    </b-card-group>

    <div class="bg-white p-2 w-100">
      <b-row class="align-items-center">
        <b-col sm="12" md="6" class="my-2 my-md-0">
          <h6 class="text-center text-md-left">
            <b>© {{ new Date().getFullYear() }} {{ appName }}</b>
            <span class="d-none d-sm-inline-block">
              . All rights Reserved
            </span>
          </h6>
        </b-col>
        <b-col sm="12" md="6" class="my-2 my-md-0">
          <section class="d-flex align-items-start justify-content-center justify-content-md-end">
            <h5><strong>Ikuti Kami</strong></h5>
            <div>
              <a href="https://facebook.com" target="_blank">
                <feather-icon class="ml-2" icon="FacebookIcon" size="18" /></a>
              <feather-icon class="ml-2" icon="TwitterIcon" size="18" />
              <a href="https://www.instagram.com" target="_blank">
                <feather-icon class="ml-2" icon="InstagramIcon" size="18" /></a>
              <feather-icon class="ml-2" icon="YoutubeIcon" size="18" />
            </div>
          </section>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
  import {
    BLink
  } from "bootstrap-vue";
  import {
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardFooter,
    BCardBody,
    BCardTitle,
    BImg,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BTabs,
    BNavItem,
    BButton,
    BTable,
    BFormGroup,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";

  export default {
    components: {
      BLink,
      BTable,
      BFormGroup,
      BRow,
      BCol,
      BCardGroup,
      BCard,
      BCardText,
      BCardFooter,
      BCardBody,
      BCardTitle,
      BTabs,
      BButton,
      BNavItem,
      BNavbar,
      BNavbarToggle,
      BCollapse,
      BImg,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        fields: ["first_name", "last_name", "age"],
        items: [],
        img: require("@/assets/images/logo/bsh.svg"),
      };
    },
  };
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

  * {
    font-family: "Open Sans", sans-serif;
  }

  @media (max-width: 1180px) {
    .hide-tablet {
      display: none;
    }
  }
</style>
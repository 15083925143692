var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "mt-2"
  }, [_c('b-card-group', [_c('b-card', {
    attrs: {
      "no-body": "",
      "bg-variant": "info",
      "text-variant": "white"
    }
  }, [_c('div', {
    staticClass: "text-left mt-4 mb-4 container",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('b-col', [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-row', {
    staticClass: "mb-2 text-white",
    attrs: {
      "align-v": "baseline"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "2",
      "md": "3"
    }
  }, [_c('b-img', {
    attrs: {
      "src": _vm.img,
      "alt": "",
      "rounded": "",
      "width": "100px"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mb-1 text-white"
  }, [_c('b-col', [_c('div', {
    staticStyle: {
      "font-size": "1em"
    }
  }, [_vm._v(" " + _vm._s(_vm.appName) + " Merupakan platform Belajar Online bagi siapa saja yang ingin mengembangkan keahlian "), _c('i', [_vm._v("(Skill)")]), _vm._v(" dan berbagi ilmu "), _c('i', [_vm._v("(Sharing Knowlegde)")]), _vm._v(" baik untuk kalangan Akademisi maupun Profesional sehingga bisa bermanfaat untuk masyarakat luas sampai kita mati menjadi Amal Jariyah. Karena setiap orang yang akan mengakses dan memberikan Donasi untuk setiap topiknya akan disalurkan pada Program Sosial "), _c('i', [_vm._v("(Charity)")]), _vm._v(" untuk kepentingan masyarakat luas seperti Beasiswa Pendidikan, Pembangunan tempat Ibadah, Santunan Dhuafa, Yatim Piatu dan Program sosial lainnya. ")])])], 1)], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('div', {
    staticClass: "mb-2 text-white",
    staticStyle: {
      "font-size": "1em"
    }
  }, [_c('b', [_vm._v("Kontak")])]), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', [_c('feather-icon', {
    attrs: {
      "icon": "PhoneIcon"
    }
  }), _vm._v(" 087825412805 ")], 1)], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticStyle: {
      "margin-right": "5px"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MailIcon"
    }
  })], 1), _c('div', {
    staticStyle: {
      "word-wrap": "break-word"
    }
  }, [_c('small', [_vm._v("info@sobari.id")])])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "mb-2 text-white",
    staticStyle: {
      "font-size": "1em"
    }
  }, [_c('b', [_vm._v("Layanan & Informasi")])]), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', [_c('b-link', {
    staticClass: "text-white",
    attrs: {
      "to": {
        name: 'tentang-kami'
      }
    }
  }, [_vm._v(" Tentang Kami ")])], 1)], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', [_c('b-link', {
    staticClass: "text-white",
    attrs: {
      "to": {
        name: 'kebijakan-privasi'
      }
    }
  }, [_vm._v(" Kebijakan Privasi ")])], 1)], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', [_c('b-link', {
    staticClass: "text-white",
    attrs: {
      "to": {
        name: 'syarat-ketentuan'
      }
    }
  }, [_vm._v(" Syarat & Ketentuan ")])], 1)], 1)], 1)], 1)], 1)], 1)])], 1), _c('div', {
    staticClass: "bg-white p-2 w-100"
  }, [_c('b-row', {
    staticClass: "align-items-center"
  }, [_c('b-col', {
    staticClass: "my-2 my-md-0",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('h6', {
    staticClass: "text-center text-md-left"
  }, [_c('b', [_vm._v("© " + _vm._s(new Date().getFullYear()) + " " + _vm._s(_vm.appName))]), _c('span', {
    staticClass: "d-none d-sm-inline-block"
  }, [_vm._v(" . All rights Reserved ")])])]), _c('b-col', {
    staticClass: "my-2 my-md-0",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('section', {
    staticClass: "d-flex align-items-start justify-content-center justify-content-md-end"
  }, [_c('h5', [_c('strong', [_vm._v("Ikuti Kami")])]), _c('div', [_c('a', {
    attrs: {
      "href": "https://facebook.com",
      "target": "_blank"
    }
  }, [_c('feather-icon', {
    staticClass: "ml-2",
    attrs: {
      "icon": "FacebookIcon",
      "size": "18"
    }
  })], 1), _c('feather-icon', {
    staticClass: "ml-2",
    attrs: {
      "icon": "TwitterIcon",
      "size": "18"
    }
  }), _c('a', {
    attrs: {
      "href": "https://www.instagram.com",
      "target": "_blank"
    }
  }, [_c('feather-icon', {
    staticClass: "ml-2",
    attrs: {
      "icon": "InstagramIcon",
      "size": "18"
    }
  })], 1), _c('feather-icon', {
    staticClass: "ml-2",
    attrs: {
      "icon": "YoutubeIcon",
      "size": "18"
    }
  })], 1)])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
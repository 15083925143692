<template>
  <li class="li dropdown dropdown-right" v-if="userData">
    <a href="#" class="user-avatar">
      <b-avatar
        size="32"
        :src="userData.photo ? apiFile + userData.photo.file : null"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="!userData.name" icon="UserIcon" size="18" />
      </b-avatar>
    </a>
    <ul class="dropdown-menu">
      <li class="dropdown-item">
        <a @click="dashboard" class="text-primary">Dashboard</a>
      </li>
      <li class="dropdown-item">
        <a @click="logout" class="text-primary">Logout</a>
      </li>
    </ul>
  </li>
  <li v-else>
    <section class="d-flex">
      <a class="btn btn-outline-info text-dark mx-1" @click="daftar">Daftar</a>
      <a class="btn btn-info" @click="masuk">Masuk</a>
    </section>
  </li>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
      isDropdownOpen: false,
    };
  },
  methods: {
    dashboard() {
      if (this.userData.level.nama_level == "Siswa") {
        this.$router.push({ name: "statistik" });
      } else if (this.userData.level.nama_level == "Super Admin") {
        this.$router.push({ name: "admin-dashboard" });
      } else {
        this.$router.push({ name: "user-admin-dashboard" });
      }
    },
    masuk() {
      this.$router.push({ name: "auth-login" });
    },
    daftar() {
      this.$router.push({ name: "auth-register" });
    },
    logout() {
      localStorage.removeItem("token");

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      localStorage.removeItem("myMenu");

      localStorage.removeItem("accessMenu");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
  },
};
</script>

<style>
.dropdown-menu {
  max-width: 180px;
  padding: 5px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  word-wrap: break-word;
  right: 0;
  left: auto;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
}

.dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-item {
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  text-align: left;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.user-avatar {
  display: inline-block;
  cursor: pointer;
}

.b-avatar {
  border-radius: 50%;
  overflow: hidden;
}

ul.ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

li.li {
  padding: 0;
  margin: 0;
  display: block;
}

.dropdown-right {
  right: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
</style>
